import mainjson from './main';
import { Level } from '../utils/LoginFunctions';

function checkIf(data) {
    if (parseInt(Level()) <= 0) {
        return true;
    }
    else {
        return false;
    }
}

export default function GetData() {
        let data = {
        "apitype":"tickettypes",
        "id":"tickettype_id",
        "subidname":"tickettype_description",
        "options":["edit","delete","insert"],
        "nameSingle":"ticket type",
        "nameMultiple":"ticket types",

      
        "fields":{
            "field1":{
                "name":"Description",
                "field":"tickettype_description",
                "type":"TextInput",
                "required":true,
                "list":true
            },
            "field2":{
                "name":"Price",
                "field":"tickettype_price",
                "type":"TextInput",
                "required":true,
                "list":true
            },
            "field100":{
                "name":"Client",
                "field":"tickettype_client_id",
                "type":"DropDown",
                "options": "usergroups",
                "list":true,
                "if":checkIf
            }, 
            


        }
    }

    return data;
}